import { css } from '@emotion/react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ShareButton from '../components/ShareButton'
import { baseGrid, inlineLinkBack } from '../theme/mixins'
import { colors } from '../theme/variables'

export const data = graphql`
  query ($gallery: String!, $id: String!) {
    galleryItem: datoCmsGalleryItem(originalId: { eq: $id }) {
      id: originalId
      image {
        gatsbyImageData(imgixParams: { q: 75 })
        alt
        focalPoint {
          x
          y
        }
      }
      cabinetString
      woodTypeString
      finishString
      cabinet {
        name
        doorStyle {
          name
        }
      }
      woodType {
        name
      }
      finish {
        ... on DatoCmsWoodStain {
          name
        }
        ... on DatoCmsWoodPaint {
          name
        }
      }
    }
    gallery: datoCmsGallery(name: { eq: $gallery }) {
      name
    }
    galleryPage: datoCmsGalleryPage {
      slug
    }
  }
`

const GalleryImagePage = ({ data, pageContext }) => {
  const { galleryItem, gallery, galleryPage } = data
  const { count, title } = pageContext
  const format = array => array.map(x => x.name).join(' + ')
  const description = `${
    gallery.name
  } cabinets gallery photo featuring a ${
    data.cabinetString || format(galleryItem.cabinet)
  }, ${data.woodTypeString || format(galleryItem.woodType)}, ${
    data.finishString || format(galleryItem.finish)
  } finished product.`

  const mainStyle = css`
    ${baseGrid}
  `
  const contentStyle = css`
    grid-column: 2 / -2;
    width: auto;
    max-width: 100%;
    background-color: white;
    padding: 2rem var(--gutter-mlg) 4rem;
    margin: var(--gutter-md) 0 0;
    align-self: center;
    justify-self: center;
    box-sizing: border-box;
  `
  const imageWrapStyle = css`
    margin-top: 2rem;
    position: relative;
    img {
      width: 100%;
      max-height: max(
        calc(100vh - 8rem - var(--gutter-md) - var(--gutter-mlg)) -
          var(--nav-height),
        33vw
      );
    }
  `
  const titleStyle = css`
    position: relative;
    font-size: var(--fs-18);
    font-weight: 400;
    margin: 0;
    height: 0;
    top: 0.5em;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
  `
  const linkStyle = css`
    grid-column: 2 / -2;
    ${inlineLinkBack}
    justify-self: center;
    font-size: var(--fs-15);
    margin: 1em 0 2em;
  `
  const shareButtonStyle = css`
    right: 0;
    bottom: 0;
  `
  return (
    <Layout mainCss={mainStyle} showCtaBar={false}>
      <Seo
        title={`${title}${count > 1 ? ' ' + count : ''} | ${
          gallery.name
        }`}
        description={description}
      />
      <div css={contentStyle}>
        <div css={imageWrapStyle}>
          <GatsbyImage
            image={galleryItem.image.gatsbyImageData}
            alt={galleryItem.image.alt || description}
            title={title}
            backgroundColor={colors.ecruShade2}
          />
          <ShareButton css={shareButtonStyle} />
        </div>
        <h1 css={titleStyle}>{title}</h1>
      </div>
      <Link to={`/${galleryPage.slug}/`} css={linkStyle}>
        Return to Gallery
      </Link>
    </Layout>
  )
}

export default GalleryImagePage
